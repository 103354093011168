@import "./rotated.scss";

@font-face {
  font-family: "comic_sans";
  src: url("./assets/comic_sans_ms.ttf");
}

@font-face {
  font-family: "comic_sans_monospace";
  src: url("./assets/ComicMono.ttf");
}

:root {
  --color-background: #111111;
  --color-file-picker: #222222;
  --color-tool-bar: #333333;
  --border-radius: 5px;
  --angle: 60deg;

  --font-family: comic_sans;
  --font-family-monospace: comic_sans_monospace;
}

body.counter-rotate {
  transform: rotate(calc(-1 * var(--angle))) scale(0.5);
}

body, html {
  padding: 0;
  margin: 0;
  height: 100%;
}

body {
  background-color: var(--color-background);
  color: white;
  font-family: var(--font-family);
  line-height: 1em;
}

#root {
  // So that any children position: absolute is relative to the root
  position: relative;
  width: 100%;
  height: 100%;
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

pre {
  padding: 0;
  margin: 0;
  font-size: inherit;
}

button {
  padding: 0;
  margin: 0;
  display: inline;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  background-color: transparent;
  border: none;
}

* {
  box-sizing: border-box;
}
