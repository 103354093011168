
/*
to-set:
  // gap between elements
  --rotcont-gap: 0.5rem;
  // height of each element
  --rotcont-height: calc(2rem);
*/
.rotated-container {
  display: block;

  display: flex;
  flex-direction: column;

  --rotcont-hc: calc((var(--rotcont-gap) + var(--rotcont-height)) / cos(var(--angle)));
  gap: calc(var(--rotcont-hc) - var(--rotcont-height));

  >.rotated-element {
    --rotcont-dl: calc(var(--rotcont-height) * tan(var(--angle)));
    height: var(--rotcont-height);
    width: calc(100% / cos(var(--angle)) - var(--rotcont-dl));

    transform: rotate(var(--angle));
    transform-origin: bottom left;
  }
}

.angle-inverted .rotated-container {
  /* justify-content: end; */
  align-items: end;

  >.rotated-element {
    transform-origin: bottom right;
    --rotcont-dl: calc(var(--rotcont-height) * tan(-1 * var(--angle)));
  }
}
